import React, { useState } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import ARStickers from 'src/components/ARStickers'
import GameQuiz from 'src/components/GameQuiz'
import 'src/styles/home.scss'
import 'src/styles/engagement.scss'

export default ({ data }) => {
  const engagement_content = data.allRestApiApiEngagement.edges[0].node.content
  const locale = data.allRestApiApiEngagement.edges[0].node.locale
  const [openGamePopup, setOpenGamePopup] = useState(false)

  const renderWelcomeSection = () => {
    return (
      <div className="welcome-section">
        <div className="title-text bold">
          {parse(engagement_content.engagement_description)}
        </div>
        <div className="title-hashtag bold">
          {engagement_content.engagement_hashtag}
        </div>
        <div className="guideline-title">
          <div className={'guideline-title-wrapper bold'}>
            {engagement_content.see_how_it_works}
          </div>
        </div>
        <div className="guideline-list-wrapper">
          {renderList(engagement_content.engagement_steps, 'number')}
        </div>
        <div className="title-hashtag smaller latest bold">
          {engagement_content.engagement_hashtag}
        </div>
      </div>
    )
  }

  const renderARStickers = () => {
    return (
      <div className="AR-stickers-section">
        <div className={'box-shadow-custom-img'}>
          <img
            src={engagement_content.background_1}
            className={'img-box-shadow'}
            alt={''}
          />
        </div>
        <div className="title-text">
          <div
            className="title-wrapper bold"
            style={{
              backgroundImage: `url(${engagement_content.ar_sticker_background})`
            }}
          >
            {' '}
            {engagement_content.ar_sticker_title}
          </div>
        </div>
        <ARStickers
          stickers={engagement_content.ar_stickers}
          url_template={engagement_content.scan_qr_code_text}
        />
      </div>
    )
  }

  const renderGamification = () => {
    return (
      <div id="gamification" className="gamification-section">
        <div
          className="gamification-banner"
          style={{
            backgroundImage: `url(${engagement_content.prumdrt_gamification_bg})`
          }}
        >
          <p className="big-title">
            {engagement_content.gamification_challenge_text}
          </p>
        </div>
        <div
          className={'gamification-content-wrapper'}
          style={{ backgroundImage: `url(${engagement_content.background_2})` }}
        >
          <div className="gamification-introduction">
            {parse(engagement_content.gamification_description)}
          </div>
        </div>
      </div>
    )
  }

  const renderList = (list, character) => {
    if (!!list && list.length > 0) {
      const symbol = !!character ? character : ''

      return list.map((item, index) => {
        return (
          <div key={index} className="child-list-component">
            <div className="text-content">
              <span className="character">
                {' '}
                {symbol === 'number' ? `${index + 1}.` : symbol}
              </span>
              {item}
            </div>
          </div>
        )
      })
    } else {
      return <div />
    }
  }

  const renderGameQuiz = () => {
    return (
      <GameQuiz
        open={openGamePopup}
        onClose={() => setOpenGamePopup(false)}
        locale={locale}
        translatedText={{
          submitting: engagement_content.submitting,
          submit: engagement_content.submit,
          next: engagement_content.next,
          previous: engagement_content.back
        }}
        labels={{
          enter_registration_id: engagement_content.enter_registration_id,
          game_login_note: engagement_content.game_login_note,
          submit: engagement_content.submit
        }}
        backgrounds={{
          quiz_bg: engagement_content.gamification_bg
        }}
      ></GameQuiz>
    )
  }

  return (
    <Layout
      locale={'cn'}
      extraClassNameWrapper={'engagement-wrapper'}
      extraMainClassName={'engagement-main'}
    >
      <SEO title="Virtual Activities" />
      <div className="home-banner-wrapper engagement">
        <img src={engagement_content.engagement_banner} alt="" />
        <div className="blend-line"></div>
      </div>
      {renderWelcomeSection()}
      {renderARStickers()}

      {/*{renderContactSection()}*/}
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiEngagement(filter: { locale: { eq: "cn" } }) {
      edges {
        node {
          locale
          content {
            engagement_description
            engagement_hashtag
            see_how_it_works
            ar_stickers {
              image
              qr_code
              qr_code_url
            }
            ar_sticker_title
            ar_sticker_background
            scan_qr_code_text
            gamification_description
            quiz_master
            two_truth_and_a_lie
            gamification_things_to_note
            gamification_will_go_live_text
            engagement_banner
            background_1
            background_2
            prumdrt_gamification_bg
            quiz_master_btn
            two_truth_and_a_lie_btn
            engagement_steps
            gamification_notes
            enter
            enter_btn
            enter_registration_id
            game_login_note
            submitting
            back
            next
            submit
            gamification_bg
            gamification_challenge_text
          }
        }
      }
    }
  }
`
